import React from 'react';
import './Time.scss';

const Time = ({ setChoosenTime, date }) => {
  const availableTimes = ['12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30'];
  const day = new Date(date).getDate();

  const isAvailable = (time) => {
    if (day === 31) {
      return availableTimes.includes(time);
    }
    return true;
  };

  const times = [
    '12:00', '12:30', '13:00', '13:30',
    '14:00', '14:30', '15:00', '15:30',
    '16:00', '16:30', '17:00', '17:30',
    '18:00', '18:30', '19:00', '19:30',
    '20:00', '20:30', '21:00', '21:30'
  ];

  return (
    <div className="time">
      <div className="time__block">
        {times.map((time) => (
          <div
            key={time}
            className='time__time-container'
            onClick={() => setChoosenTime(time)}
          >
            <p className="time__hour">{time}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Time;
